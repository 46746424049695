<template>
  <div id="flyfishoutlets">
    <header>
      <div class="headerTop">
        <div class="container">
          <div class="logo">
            <h1>
              <img src="@/assets/images/logo.png" alt="logo" />
            </h1>
          </div>
          <ul>
            <li><router-link to="/">首页</router-link></li>
            <li><router-link to="wisdom">智量百业</router-link></li>
            <li><router-link to="shop">同城好店</router-link></li>
            <li><router-link to="assistant">抖微助手</router-link></li>
            <li class="cur">
              <router-link to="flyfishoutlets">飞鱼系统</router-link>
            </li>
            <li><router-link to="commerce">跨境电商</router-link></li>
            <!-- <li><router-link to="contact">联系我们</router-link></li> -->
            <li><router-link to="about">关于我们</router-link></li>
          </ul>
        </div>
      </div>
      <img src="@/assets/images/flyfishoutlets_banner1.jpg" alt="" />
    </header>
    <section class="container pd100 fish">
      <div class="item-title">
        <img src="@/assets/images/title-icon.jpg" alt="" />
        <span>一站式开店整体解决方案</span>
        <p>提供傻瓜式SAAS店铺工具以及一站式社交电商解决方案</p>
      </div>
      <div class="main">
        <img src="@/assets/images/fish-bg.jpg" alt="" />
        <div class="item item_1">
          <img src="@/assets/images/circle_1.jpg" alt="" />
          <p>一键免费开店</p>
        </div>
        <div class="item item_2">
          <img src="@/assets/images/circle_2.jpg" alt="" />
          <p>集成微信/抖音</p>
          <p>短视频直播</p>
        </div>
        <div class="item item_3">
          <img src="@/assets/images/circle_3.jpg" alt="" />
          <p>一键自动铺货</p>
        </div>
        <div class="item item_4">
          <img src="@/assets/images/circle_4.jpg" alt="" />
          <p>10W+热门</p>
          <p>商品库</p>
        </div>
        <div class="item item_5">
          <img src="@/assets/images/circle_5.jpg" alt="" />
          <p>一键自动化</p>
          <p>运营</p>
        </div>
        <div class="item item_6">
          <img src="@/assets/images/circle_6.jpg" alt="" />
          <p>专业服务团队</p>
        </div>
      </div>
    </section>
    <section class="container pd100 shop_2">
      <div class="item-title">
        <img src="@/assets/images/title-icon.jpg" alt="" />
        <span>整体解决方案详情</span>
        <p>一站式社交电商解决方案详情介绍</p>
      </div>
      <div class="main">
        <div class="item">
          <img src="@/assets/images/fish-1.jpg" alt="" />
          <ul>
            <li>
              <img src="@/assets/images/shop-icon.jpg" alt="" />
              <span>根据用户画像自动初始化店铺</span>
            </li>
            <li>
              <img src="@/assets/images/shop-icon.jpg" alt="" />
              <span>一键分享赚钱， 高毛利商品与丰富素材库</span>
            </li>
            <li>
              <img src="@/assets/images/shop-icon.jpg" alt="" />
              <span>与线下实体店同步，创新模式新零售</span>
            </li>
            <li>
              <img src="@/assets/images/shop-icon.jpg" alt="" />
              <span>一键分享赚钱， 高毛利商品与丰富素材库</span>
            </li>
            <li>
              <img src="@/assets/images/shop-icon.jpg" alt="" />
              <span>一键提现自动到账，销售明细一目了然</span>
            </li>
          </ul>
        </div>
        <div class="item">
          <ul>
            <li>
              <img src="@/assets/images/shop-icon.jpg" alt="" />
              <span>根据店主用户画像及规则，自动化运营店铺</span>
            </li>
            <li>
              <img src="@/assets/images/shop-icon.jpg" alt="" />
              <span>独创裂变引流系统，1变5、5变25指数级裂变</span>
            </li>
            <li>
              <img src="@/assets/images/shop-icon.jpg" alt="" />
              <span>社群机器人智能推品及应答，提升浏览购买转化率</span>
            </li>
            <li>
              <img src="@/assets/images/shop-icon.jpg" alt="" />
              <span>社群红包及活动智能推送，活跃用户提升复购率</span>
            </li>
          </ul>
          <img src="@/assets/images/fish-2.jpg" alt="" />
        </div>
        <div class="item">
          <img src="@/assets/images/fish-3.jpg" alt="" />
          <ul>
            <li>
              <img src="@/assets/images/shop-icon.jpg" alt="" />
              <span
                >与1000+品牌达成合作，覆盖母婴、美妆、
                <br />优化家居、时尚、零食等全品类
              </span>
            </li>
            <li>
              <img src="@/assets/images/shop-icon.jpg" alt="" />
              <span>5级品控严格把关，正品承诺，假一赔十</span>
            </li>
            <li>
              <img src="@/assets/images/shop-icon.jpg" alt="" />
              <span>品牌方和上游供应链直供，确保低价</span>
            </li>
            <li>
              <img src="@/assets/images/shop-icon.jpg" alt="" />
              <span>支持店主自营商品上架销售</span>
            </li>
          </ul>
        </div>
        <div class="item">
          <ul>
            <li>
              <img src="@/assets/images/shop-icon.jpg" alt="" />
              <span>店主1对1专属运营指导</span>
            </li>
            <li>
              <img src="@/assets/images/shop-icon.jpg" alt="" />
              <span>专业客服及时响应，售后无忧</span>
            </li>
            <li>
              <img src="@/assets/images/shop-icon.jpg" alt="" />
              <span>专业顾问店铺把脉，提供选品和内容策划</span>
            </li>
            <li>
              <img src="@/assets/images/shop-icon.jpg" alt="" />
              <span>7天放心退，保障用户权益和体验</span>
            </li>
          </ul>
          <img src="@/assets/images/fish-4.jpg" alt="" />
        </div>
      </div>
    </section>
    <section class="pd100 container serve">
      <div class="item-title">
        <img src="@/assets/images/title-icon.jpg" alt="" />
        <span>全方位的流量主服务体系</span>
        <p>一站式涨粉、内容策划、电商运营等专业流量主服务体系</p>
      </div>
      <ul class="main">
        <li>
          <img src="@/assets/images/fish-5.jpg" alt="" />
          <p>涨粉指导</p>
        </li>
        <li>
          <img src="@/assets/images/fish-6.jpg" alt="" />
          <p>运营培训</p>
        </li>
        <li>
          <img src="@/assets/images/fish-7.jpg" alt="" />
          <p>内容策划</p>
        </li>
        <li>
          <img src="@/assets/images/fish-8.jpg" alt="" />
          <p>选品对接</p>
        </li>
        <li>
          <img src="@/assets/images/fish-9.jpg" alt="" />
          <p>选品对接</p>
        </li>
      </ul>
    </section>
    <section class="container fish_3">
      <div class="item-title">
        <img src="@/assets/images/title-icon.jpg" alt="" />
        <span>SAAS店铺工具介绍</span>
        <p>傻瓜式、简洁易用的店铺工具</p>
      </div>
      <ul class="main">
        <li>
          <img src="@/assets/images/fish_3-1.jpg" alt="" />
          <div class="item-right">
            <h4>1个大中台</h4>
            <p>抖音/微信等多平台订单集中管理</p>
            <p>营销素材多平台多社群统一分发</p>
            <p>大数据个性化推荐</p>
          </div>
        </li>
        <li>
          <img src="@/assets/images/fish_3-2.jpg" alt="" />
          <div class="item-right">
            <h4>3种轻前端</h4>
            <p>微商城/微信/抖音小程序</p>
            <p>账号用户数据同步</p>
            <p>多平台同时引流</p>
            <p>店铺支持二级分销</p>
          </div>
        </li>
        <li>
          <img src="@/assets/images/fish_3-3.jpg" alt="" />
          <div class="item-right">
            <h4>5大智能技术</h4>
            <p>智能推品/智能裂变/智能应答/智能客服/智能统计</p>
            <p>高效率、低成本,轻松当甩手掌柜</p>
          </div>
        </li>
        <li>
          <img src="@/assets/images/fish_3-4.jpg" alt="" />
          <div class="item-right">
            <h4>7大引流系统</h4>
            <p>1变5、5变25指数级裂变增长</p>
            <p>分享引流自动锁客</p>
            <p>搭建私域流量池,变现闭环</p>
          </div>
        </li>
      </ul>
    </section>
    <section class="pd100 fish_4">
      <div class="item-title">
        <img src="@/assets/images/title-icon.jpg" alt="" />
        <span>SAAS商城演示</span>
        <p>独立品牌、个性化装修、自动化运营、傻瓜化流畅操作</p>
      </div>
      <div class="main">
        <ul class="container">
          <li>
            <img src="@/assets/images/fish_4-1.jpg" alt="" />
            <p>微商城演示</p>
          </li>
          <li>
            <img src="@/assets/images/fish_4-2.jpg" alt="" />
            <p>微信小程序演示</p>
          </li>
          <li>
            <img src="@/assets/images/fish_4-3.jpg" alt="" />
            <p>抖音小程序演示</p>
          </li>
        </ul>
      </div>
    </section>
     <section class="pd60 contact">
      <div class="container">
        <div class="item-title">
          <img src="@/assets/images/title-icon.jpg" alt="" />
          <span>业务合作</span>
        </div>
      </div>
      <div class="main">
        <div class="container">
          <img src="@/assets/images/contact-2.png" alt="" />
          <p>
            请将合作资料发送至邮箱：1487986688@qq.com
            或者加微信：13410419943
          </p>
        </div>
      </div>
    </section>
    <footer>
      <div class="main container pd60">
        <div class="item">
          <h4>关于我们</h4>
          <p>公司介绍</p>
          <p>业务合作</p>
        </div>
        <div class="item">
          <h4></h4>
          <p>邮箱：1487986688@qq.com</p>
          <p>电话（微信同名）：13410419943，周一至周日 9:30 - 22:30</p>
          <!-- <p>地址：深圳市福田区车公庙泰然八路安华工业区6栋613</p> -->
        </div>
        <div class="wx">
          <div class="wx-item">
            <img src="@/assets/images/wx-1.jpg" alt="" />
            <p>扫码关注公众号</p>
          </div>
          <div class="wx-item">
            <img src="@/assets/images/wx-2.jpg" alt="" />
            <p>业务合作</p>
          </div>
        </div>
      </div>
      <div class="copyright container pd60">
        <p>©2019-2024 深圳漠阳风科技有限公司 版权所有</p>
        <a href="http://beian.miit.gov.cn/">粤ICP备19157531号-3</a>
      </div>
    </footer>
  </div>
</template>
<script>
import '@/assets/css/base.css'
import '@/assets/css/style.css'
export default {}
</script>
<style lang="less" scoped>
.fish .main {
  height: 910px;
  position: relative;
  .item {
    position: absolute;
    text-align: center;
    width: 270px;
    height: 270px;
    border-radius: 50%;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 30px;
  }
  .item_1 {
    top: 0;
    left: 110px;
  }
  .item_2 {
    top: 0;
    right: 110px;
  }
  .item_3 {
    top: 312px;
    left: 15px;
  }
  .item_4 {
    top: 312px;
    right: 15px;
  }
  .item_5 {
    left: 106px;
    bottom: 0;
  }
  .item_6 {
    right: 106px;
    bottom: 0;
  }
}
.fish .main > img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.shop_2 .main {
  .item {
    ul {
      width: 485px;
    }
  }
  .item:nth-child(n + 2) {
    border-top: 1px solid #ccc;
    padding-top: 60px;
  }
}
.fish_3 {
  margin-top: 100px;
  .main {
    display: flex;
    flex-wrap: wrap;
    li {
      width: 50%;
      display: flex;
      margin-bottom: 120px;
      .item-right {
        width: 210px;
        margin-left: 30px;
        h4 {
          position: relative;
          font-size: 32px;
          color: #303030;
          padding: 20px 0;
        }
        h4::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 30px;
          height: 3px;
          background-color: #0099e0;
        }
        p {
          font-size: 20px;
          color: #6e6e6e;
          margin-top: 20px;
        }
      }
    }
  }
}
.fish_4 .main {
  background: url(../assets/images/fish_4-bg.jpg) no-repeat;
  ul {
    display: flex;
    justify-content: space-around;
    text-align: center;
    img {
      margin-bottom: 30px;
    }
    p {
      font-size: 26px;
      color: #303030;
    }
  }
}
</style>
